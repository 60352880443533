<template>
  <div class="cardTransContainer" @click.stop="selectedTrans = !selectedTrans">
    <div class="cardTransItemIcon">{{ inicial }}</div>
    <div class="cardTransItemInfo">
      <div class="cardTransItemInfoFecha">{{ fecha_ingreso }}</div>
      <div class="cardTransItemInfoNombre">{{ nombre }}</div>
      <div class="cardTransItemInfo_entregado">{{ monto_entregado }}</div>

      <div class="cardTransItemInfoDetalle" v-if="selectedTrans">
        <div class="cardTransItemInfoForma">{{ formaEntrega }}</div>
        <div class="cardTransItemInfoEstado" v-if="estadoTransaction == 'ok'">
          <div class="imgContainer">
            <img
              :src="iconEstadosTransacBienvUno"
              alt=""
              srcset=""
              v-if="estadoTransac_step1 == 'por_pagar'"
            />
            <img
              :src="iconEstadosTransacBienvUnoPagado"
              alt=""
              srcset=""
              v-if="estadoTransac_step1 == 'pagado'"
            />
            <img
              :src="iconEstadosTransacBienvDos"
              alt=""
              srcset=""
              v-if="estadoTransac_step2 == 'enviado'"
            />
            <img
              :src="iconEstadosTransacBienvTres"
              alt=""
              srcset=""
              v-if="
                estadoTransac_step3 == 'entregado' ||
                estadoTransac_step3 == 'retirado'
              "
            />
          </div>
          <div class="cardTransItemInfoEstadoText">
            <span class="superado" v-if="estadoTransac_step1 == 'por_pagar'">{{
              t("app.cardTransaction.por_pagar")
            }}</span>
            <span
              class="superado"
              v-if="
                estadoTransac_step1 == 'pagado' ||
                estadoTransac_step2 == 'enviado' ||
                estadoTransac_step3 == 'entregado' ||
                estadoTransac_step3 == 'retirado'
              "
              >{{ t("app.cardTransaction.pagado") }}</span
            >
            <span
              :class="
                estadoTransac_step2 == 'enviado' ||
                estadoTransac_step3 == 'entregado' ||
                estadoTransac_step3 == 'retirado'
                  ? 'superado'
                  : 'pendiente'
              "
              >{{ t("app.cardTransaction.estadoDos") }}</span
            >
            <span v-if="estadoTransac_step3 == ''" class="pendiente">{{
              text_estado_final
            }}</span>
            <span
              v-if="
                estadoTransac_step3 == 'entregado' ||
                estadoTransac_step3 == 'retirado'
              "
              :class="
                estadoTransac_step3 == 'entregado' ||
                estadoTransac_step3 == 'retirado'
                  ? 'superado'
                  : 'pendiente'
              "
              >{{
                t(`app.cardTransaction.estadoTres_${estadoTransac_step3}`)
              }}</span
            >
          </div>
        </div>

        <div class="cardTransItemInfoEstado" v-else>
          <div class="imgContainer">
            <img
              :src="iconEstadosTransacBienvUno_error"
              alt=""
              srcset=""
              v-if="state_error_post_inscripcion == 'error'"
            />
            <img
              :src="iconEstadosTransacBienvUnoPagado_error"
              alt=""
              srcset=""
              v-if="state_error_post_pago == 'error'"
            />
            <img
              :src="iconEstadosTransacBienvDos_error"
              alt=""
              srcset=""
              v-if="state_error_post_envio == 'error'"
            />
          </div>
          <div
            class="cardTransItemInfoEstadoText"
            v-if="estadoTransaction == 'ok'"
          >
            <span class="superado" v-if="estadoTransac_step1 == 'por_pagar'">{{
              t("app.cardTransaction.por_pagar")
            }}</span>
            <span
              class="superado"
              v-if="
                estadoTransac_step1 == 'pagado' ||
                estadoTransac_step2 == 'enviado' ||
                estadoTransac_step3 == 'entregado' ||
                estadoTransac_step3 == 'retirado'
              "
              >{{ t("app.cardTransaction.pagado") }}</span
            >
            <span
              :class="
                estadoTransac_step2 == 'enviado' ||
                estadoTransac_step3 == 'entregado' ||
                estadoTransac_step3 == 'retirado'
                  ? 'superado'
                  : 'pendiente'
              "
              >{{ t("app.cardTransaction.estadoDos") }}</span
            >
            <span v-if="estadoTransac_step3 == ''" class="pendiente">{{
              text_estado_final
            }}</span>
            <span
              v-if="
                estadoTransac_step3 == 'entregado' ||
                estadoTransac_step3 == 'retirado'
              "
              :class="
                estadoTransac_step3 == 'entregado' ||
                estadoTransac_step3 == 'retirado'
                  ? 'superado'
                  : 'pendiente'
              "
              >{{
                t(`app.cardTransaction.estadoTres_${estadoTransac_step3}`)
              }}</span
            >
          </div>

          <div class="cardTransItemInfoEstadoText" v-else>
            <span
              class="superado"
              v-if="state_error_post_inscripcion == 'error'"
              >{{ t("app.cardTransaction.por_pagar") }}</span
            >
            <span
              class="superado"
              v-if="
                state_error_post_pago == 'error' ||
                state_error_post_envio == 'error'
              "
              >{{ t("app.cardTransaction.pagado") }}</span
            >
            <span
              v-if="state_error_post_envio == 'error'"
              :class="
                estadoTransac_step2 == 'enviado' ||
                estadoTransac_step3 == 'entregado' ||
                estadoTransac_step3 == 'retirado'
                  ? 'superado'
                  : 'pendiente'
              "
              >{{ t("app.cardTransaction.estadoDos") }}</span
            >
            <span v-else class="pendiente">&nbsp;</span>
            <span class="pendiente">&nbsp;</span>
          </div>
        </div>

        <div class="cardTransItem_esperando_pago" v-if="estadoTransac_step1 == 'por_pagar'">
          <img :src="alert" alt="">
          <span>Estamos esperando tu pago</span>
          </div>

        <div class="cardTransItemInfoBtns">
          <div
            class="cardTransItemInfoBtnsRepetir cardTransItemInfoBtns_style cardTransItemInfoBtnsRepetir_ok"
            @click.stop="repetirTransac"
            v-if="transaction.vigencia_pais"
          >
            {{ t("app.cardTransaction.btnRepetir") }}
          </div>
          <div
            class="cardTransItemInfoBtnsRepetir cardTransItemInfoBtns_style cardTransItemInfoBtnsRepetir_bloqueado"
            @click.stop="
              repetirTransac_bloqueado({
                pais: transaction.vigencia_pais,
                destinatario: transaction.vigencia_destinatario,
              })
            "
            v-else
          >
            {{ t("app.cardTransaction.btnRepetir") }}
          </div>
          <div
            class="cardTransItemInfoBtnsDetalle cardTransItemInfoBtns_style"
            @click.stop="mostrarDetalleTransac"
          >
            {{ t("app.cardTransaction.btnDetalle") }}
          </div>
        </div>
        
      </div>
      <div></div>
    </div>
    <div class="cardTransItemSelect">
      <div>
        <img
          :src="iconChevron"
          alt=""
          srcset=""
          :class="selectedTrans ? 'selected' : ''"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { useI18n } from "vue-i18n";
import { mapActions, mapState } from "vuex";

export default {
  setup() {
    const { t, locale } = useI18n();
    return { t, locale };
  },
  name: "cardTransaction",
  props: {
    transaction: Object,
    index: Number,
    transactionSelectedIndex: Number,
  },
  data() {
    return {
      iconChevron: require("../../assets/imgs/iconChevronWhite.svg"),
      selectedTrans: false,
      iconEstadosTransacBienvUno: require("../../assets/imgs/iconEstadosTransacBienvUno.svg"),
      iconEstadosTransacBienvUnoPagado: require("../../assets/imgs/iconEstadosTransacBienvUnoPagado.svg"),
      iconEstadosTransacBienvDos: require("../../assets/imgs/iconEstadosTransacBienvDos.svg"),
      iconEstadosTransacBienvTres: require("../../assets/imgs/iconEstadosTransacBienvTres.svg"),

      iconEstadosTransacBienvUno_error: require("../../assets/imgs/iconEstadosTransacBienvUno_error.svg"),
      iconEstadosTransacBienvUnoPagado_error: require("../../assets/imgs/iconEstadosTransacBienvUnoPagado_error.svg"),
      iconEstadosTransacBienvDos_error: require("../../assets/imgs/iconEstadosTransacBienvDos_error.svg"),
      alert:require('../../assets/imgs/home/alert.svg'),
    };
  },
  computed: {
    ...mapState(["configuracion", "i18n_locales",]),

    inicial() {
      let aux = "";
      if (this.transaction) {
        aux = this.transaction.destinatario_nombres.substring(0, 1);
      }
      return aux;
    },
    fecha_ingreso() {
      let aux = "";
      if (this.transaction && this.transaction.fecha_ingreso) {
        let aux2 = this.transaction.fecha_ingreso.split("-");
        if (this.locale == "es") {
          aux = `${aux2[2]} / ${aux2[1]} / ${aux2[0]}`;
        } else {
          aux = `${aux2[0]} / ${aux2[1]} / ${aux2[2]}`;
        }
      }
      return aux;
    },
    nombre() {
      let aux = "";
      if (this.transaction) {
        aux = `${this.transaction.destinatario_nombres}`;
      }
      return aux;
    },
    formaEntrega() {
      // console.log(this.transaction);
      let aux = "";
      if (this.transaction) {
        if (this.transaction.forma_entrega_id == "1") {
          aux = `${this.t("app.cotizadorDestinatario.deposito")} / ${
            this.transaction.banco
          }`;
        } else if (this.transaction.forma_entrega_id == "2") {
          aux = `${this.t("app.cotizadorDestinatario.entregaCaja")} / ${
            this.transaction.banco
          }`;
        } else if (this.transaction.forma_entrega_id == "6") {
          aux = `${this.t("app.cotizadorDestinatario.ewallet")} / ${
            this.transaction.banco
          }`;
        }
      }
      return aux;
    },
    estadoTransaction() {
      let aux = "ok";
      if (this.transaction) {
        if (
          this.transaction.id_estado_pago == "3" ||
          this.transaction.id_estado_envio == "4" ||
          this.transaction.id_estado_envio == "5" ||
          this.transaction.id_estado_envio == "6" ||
          this.transaction.id_estado_retiro == "3"
        ) {
          aux = `error`;
        }
      }
      return aux;
    },
    estadoTransac_step1() {
      let aux = "";
      if (this.transaction) {
        if (this.transaction.id_estado_pago == "3") {
          aux = `error`;
        } else if (
          this.transaction.id_estado_pago == "1" &&
          this.transaction.id_estado_envio == "1" &&
          this.transaction.id_estado_retiro == "1"
        ) {
          aux = `por_pagar`;
        } else if (
          this.transaction.id_estado_pago == "2" &&
          (this.transaction.id_estado_envio == "1" ||
            this.transaction.id_estado_envio == "2") &&
          this.transaction.id_estado_retiro == "1"
        ) {
          aux = `pagado`;
        }
      }
      // console.log("estadoTransac_step1", aux, this.transaction.id_estado_pago, this.transaction.id_estado_envio, this.transaction.id_estado_retiro);
      return aux;
    },
    estadoTransac_step2() {
      let aux = "";
      if (this.transaction) {
        if (
          this.transaction.id_estado_envio == "4" ||
          this.transaction.id_estado_envio == "5" ||
          this.transaction.id_estado_envio == "6"
        ) {
          aux = `error`;
        } else if (
          this.transaction.id_estado_pago == "2" &&
          this.transaction.id_estado_envio == "3" &&
          this.transaction.id_estado_retiro == "1"
        ) {
          aux = `enviado`;
        }
      }
      // console.log("estadoTransac_step2", aux, this.transaction.id_estado_pago, this.transaction.id_estado_envio, this.transaction.id_estado_retiro);
      return aux;
    },
    estadoTransac_step3() {
      let aux = "";
      if (this.transaction) {
        if (this.transaction.id_estado_retiro == "3") {
          aux = `error`;
        } else if (
          this.transaction.id_estado_pago == "2" &&
          this.transaction.id_estado_envio == "3" &&
          this.transaction.id_estado_retiro == "2"
        ) {
          if (
            this.transaction.forma_entrega_id == "1" ||
            this.transaction.forma_entrega_id == "6"
          ) {
            aux = `entregado`;
          } else if (this.transaction.forma_entrega_id == "2") {
            aux = `retirado`;
          }
        }
      }
      // console.log("estadoTransac_step3", aux, this.transaction.id_estado_pago, this.transaction.id_estado_envio, this.transaction.id_estado_retiro);
      return aux;
    },
    state_error_post_inscripcion() {
      let aux = "";
      if (this.transaction) {
        if (
          this.transaction.id_estado_pago == "3" &&
          (this.transaction.id_estado_envio == "1" ||
            this.transaction.id_estado_envio == "2") &&
          this.transaction.id_estado_retiro == "1"
        ) {
          aux = `error`;
        }
      }
      return aux;
    },
    state_error_post_pago() {
      let aux = "";
      if (this.transaction) {
        if (
          this.transaction.id_estado_pago == "2" &&
          (this.transaction.id_estado_envio == "4" ||
            this.transaction.id_estado_envio == "5" ||
            this.transaction.id_estado_envio == "6") &&
          this.transaction.id_estado_retiro == "1"
        ) {
          aux = `error`;
        }
      }
      return aux;
    },
    state_error_post_envio() {
      let aux = "";
      if (this.transaction) {
        if (
          this.transaction.id_estado_pago == "2" &&
          this.transaction.id_estado_envio == "3" &&
          this.transaction.id_estado_retiro == "3"
        ) {
          aux = `error`;
        }
      }
      return aux;
    },
    text_estado_final() {
      let aux = "";
      if (
        this.transaction.forma_entrega_id == "1" ||
        this.transaction.forma_entrega_id == "6"
      ) {
        aux = this.t("app.cardTransaction.estadoTres_entregado");
      } else if (this.transaction.forma_entrega_id == "2") {
        aux = this.t("app.cardTransaction.estadoTres_retirado");
      }
      return aux;
    },
    monto_entregado() {
      let aux = "";
      let moneda_index = this.configuracion.monedas.findIndex((moneda) => {
        return moneda.id == this.transaction.moneda_destino_id;
      });
      if (moneda_index > -1) {
        let aux2 = "";
        if (this.transaction.moneda_destino_id == "2") {
          aux2 = this.format_destin_dolar(this.transaction.monto_destino);
        } else {
          aux2 = this.formatNumber(this.transaction.monto_destino);
        }
        aux = `${this.configuracion.monedas[moneda_index].sigla} ${aux2} - ${this.transaction.pais_destino_nombre}`;
      }

      return aux;
    },
  },
  methods: {
    ...mapActions([]),

    formatNumber(numerito) {
      if (numerito) {
        return numerito.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1.");
      } else {
        return numerito;
      }
    },

    mostrarDetalleTransac() {
      this.$emit("mostrarDetalleTransac", this.index);
    },
    repetirTransac() {
      this.$emit("repetirTransac", this.index);
    },
    repetirTransac_bloqueado(motivo) {
      let dato = "";
      if (!motivo.pais) {
        dato = "pais";
      } else if (!motivo.destinatario) {
        dato = "destinatario";
      }
      this.$emit("repetirTransac_bloqueado", dato);
    },
    format_destin_dolar(destino) {
      let decimales_index = this.configuracion.monedas.findIndex((moneda) => {
        return moneda.id == this.transaction.moneda_destino_id;
      });
      let decimales = 0;
      if (decimales_index > -1) {
        decimales =
          this.configuracion.monedas[decimales_index].cantidad_decimales;
      }

      let aux = Math.pow(10, decimales);

      if (typeof destino == "string") {
        let aux3 = destino.split(".");
        let aux2 = ``;
        if (decimales == 1) {
          if (!aux3[1] || aux3[1].length == 0) {
            aux2 = `${aux3[0]}0.0`;
          } else if (aux3[1] && aux3[1].length == 1) {
            aux2 = `${aux3[0]}.${aux3[1]}`;
          } else if (aux3[1] && aux3[1].length == 2) {
            aux2 = `${aux3[0]}${aux3[1].substring(0, 1)}.${aux3[1].substring(
              1,
              1
            )}`;
          }
        } else if (decimales == 2) {
          if (!aux3[1] || aux3[1].length == 0) {
            aux2 = `${aux3[0]}00.0`;
          } else if (aux3[1] && aux3[1].length == 1) {
            aux2 = `${aux3[0]}${aux3[1]}0.0`;
          } else if (aux3[1] && aux3[1].length == 2) {
            aux2 = `${aux3[0]}${aux3[1]}.0`;
          }
        }

        destino = Math.floor(aux2) / aux;
      } else {
        let aux2 = destino;
        for (let index = 0; index < decimales; index++) {
          aux2 = aux2 * 10;
        }
        destino = Math.floor(aux2) / aux;
      }

      destino = parseFloat(destino);

      destino = destino.toLocaleString(
        `${this.locale}${this.i18n_locales[this.locale].locales}`
      );

      return destino;
    },
  },
  watch: {
    selectedTrans() {
      if (this.selectedTrans) {
        this.$emit("transactionSelected", this.index);
      }
    },
    transactionSelectedIndex() {
      if (this.transactionSelectedIndex != this.index) {
        this.selectedTrans = false;
      }
    },
  },
  mounted() {
    // console.log(this.transaction);
  },
};
</script>

<style>
.cardTransContainer {
  display: flex;
  padding-bottom: 1rem;

  background: #ffffff;
  border-radius: 10px;
  padding: 0.5rem 0.5rem 1rem 0.5rem;
  margin-bottom: 0.5rem;
}
.cardTransItemIcon {
  width: 2.5rem;
  height: 2.5rem;
  border-radius: 30px;
  background: #ffffff;
  box-shadow: 0px 1px 8px rgba(0, 0, 0, 0.1);

  font-family: "Roboto";
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 23px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #c4c4c4;
}
.cardTransItemInfo {
  padding-left: 1.5rem;
  width: 80%;
}
.cardTransItemInfoFecha {
  font-family: "Roboto";
  font-style: italic;
  font-weight: 500;
  font-size: 10px;
  line-height: 12px;
  display: flex;
  align-items: flex-end;
  letter-spacing: 1px;
  color: var(--customG-787878);
}
.cardTransItemInfoNombre {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 16px;
  display: flex;
  align-items: center;
  letter-spacing: 0.5px;

  color: #801b1c;
}
.cardTransItemInfo_entregado {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 16px;
  display: flex;
  align-items: center;
  letter-spacing: 0.5px;

  color: var(--customC-614AD3);
}
.cardTransItemInfoForma {
  font-family: "Roboto";
  font-style: italic;
  font-weight: 400;
  font-size: 8px;
  line-height: 9px;
  display: flex;
  align-items: flex-end;
  letter-spacing: 1px;
  color: var(--customG-787878);
}

.cardTransItemInfoEstado {
  width: 100%;
  height: fit-content;
  display: block;
}
.imgContainer {
  margin: -0.2rem 0 0 0;
  height: 20px;
}
.cardTransItemInfoEstado img {
  width: 100%;
  margin: 0;
}
.cardTransItemInfoEstadoText {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 8px;
  line-height: 9px;
  display: flex;
  justify-content: space-between;
}
.cardTransItemInfoEstadoText .superado {
  color: var(--customC-614AD3);
}
.cardTransItemInfoEstadoText .pendiente {
  color: var(--customG-787878);
}

.cardTransItemInfoBtns {
  display: flex;
  justify-content: space-between;
  padding-top: 0.5rem;
}
.cardTransItemInfoBtns_style {
  font-weight: 500;
  font-size: 8px;
  line-height: 9px;
  padding: 0.34rem 0.5rem;
}
.cardTransItemInfoBtnsRepetir {
  border-radius: 5px;
  font-family: "Roboto";
  font-style: normal;
  display: flex;
  align-items: flex-end;
  text-align: center;
  color: #ffffff;
}
.cardTransItemInfoBtnsRepetir_ok {
  background: #614ad3;
  cursor: pointer;
}
.cardTransItemInfoBtnsRepetir_bloqueado {
  background-color: #cccccc;
  color: #666666;
}
.cardTransItemInfoBtnsDetalle {
  background: #ffffff;
  border: 1.5px solid #614ad3;
  border-radius: 5px;
  font-family: "Roboto";
  font-style: normal;
  display: flex;
  align-items: flex-end;
  color: #614ad3;
  cursor: pointer;
}
.cardTransItemSelect {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  width: 2rem;
  height: 2.5rem;
  margin-right: 0.5rem;
  margin-left: auto;
}

.cardTransItemSelect div {
  height: 1.25rem;
  width: 1.25rem;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  background: #c4c4c4;
  border-radius: 5px;
  cursor: pointer;
}
.cardTransItemSelect .selected {
  -webkit-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  transform: rotate(180deg);
}

.cardTransItem_esperando_pago{
  margin-left: -3rem;
  height: fit-content;
  padding-top: 0.5rem;
  font-weight: 500;
    font-size: 0.8rem;
  color: #801b1c;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}
.cardTransItem_esperando_pago img{
  width: 0.8rem;
}
.cardTransItem_esperando_pago span{
  height: 100%;
  padding-left: 1rem;
  line-height: 0.8rem;
  /* padding-top: 0.1rem; */
}

/* ////////////////-------------------------------------/////////// */
@media (min-width: 400px) {
  .cardTransItemInfoBtns_style {
    font-weight: 500;
    font-size: 10px;
    line-height: 12px;
    padding: 0.34rem 1rem;
    letter-spacing: 0.5px;
  }
}
/* ////////////////-------------------------------------/////////// */
@media (min-width: 500px) {
  .cardTransItemInfoBtns_style {
    font-weight: 500;
    font-size: 10px;
    line-height: 12px;
    padding: 0.34rem 1.5rem;
    letter-spacing: 1px;
  }
}
</style>