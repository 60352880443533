<template>
  <div class="body250">
    <div class="header_top">
      <headerCerrar @cancelar="cerrarModal" colorIcon="gris" />
    </div>

    <div class="reciboTransfTitle title">
      {{ t("app.reciboTransferencia.tit") }}
    </div>

    <div class="reciboTransfItemsCont">
      <div v-for="(item, i) in detalleReciboTransferencia" :key="i">
        <cardReciboTransf :itemDetalle="item" />
      </div>
    </div>

    <!-- <div class="reciboTransfFooter">
      <enviosSeguros @callEnvioSeguroModal_="callEnvioSeguroModal" />
    </div> -->
  </div>
</template>

<script>
import { modalController } from "@ionic/vue";
import { useI18n } from "vue-i18n";
import headerCerrar from "../../components/generales/headerCerrar.vue";
// import enviosSeguros from "../../components/generales/envios_seguros.vue";
import envioSeguroHome from "./enviosSegurosHome.vue";
import { mapActions, mapMutations, mapState } from "vuex";
import cardReciboTransf from "../../components/generales/cardReciboTransf.vue";

export default {
  setup() {
    const { t } = useI18n();
    return { t };
  },
  props: {
    // dataTransfer: Object,
  },
  name: "reciboTransferencia",
  components: {
   
    headerCerrar,
    cardReciboTransf,
    // enviosSeguros,
  },
  data() {
    return {};
  },
  computed: {
    ...mapState(["detalleReciboTransferencia"]),
    // montoTransFerido(){
    //     let aux = {itemDescript: "", itenData: ""};
    //     if(this.dataTransfer){

    //     }
    //     return aux;
    // },
    // destinatario(){

    // },
    // fechaTransferencia(){

    // },
    // tipoEntrega(){

    // }
  },
  methods: {
    ...mapMutations(["updateBloquearNavegacion"]),
    ...mapActions([]),

    cerrarModal() {
      modalController.dismiss({ dismissed: true });
    },
    async callEnvioSeguroModal() {
      this.updateBloquearNavegacion(true);

      const modal = await modalController.create({
        component: envioSeguroHome,
        // cssClass: 'my-custom-class',
        // componentProps: {
        //   dataTransfer: data
        // },
      });
      modal.onDidDismiss().then(() => {
        this.updateBloquearNavegacion(false);
      });
      return modal.present();
    },
  },
  mounted() {
    // console.log(this.detalleReciboTransferencia);
  },
};
</script>

<style scoped>
.reciboTransfTitle {
  color: #614ad3;
  margin-top: 1.125rem;
}
.reciboTransfItemsCont {
  margin-top: 1.5625rem;
  height: 28rem;
  /* border: 1px solid green; */
}
/* .reciboTransfFooter {
  margin-top: 0.6rem;
  margin-bottom: 2rem;
} */
/* .reciboTransfFooterDestacado{
  text-decoration: underline;
} */
</style>