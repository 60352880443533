<template>
  <div class="cardReciboTransfCont">
    <div class="cardReciboTransfContItem">{{ detalle[0] }}</div>
    <div class="cardReciboTransfContValor">{{ detalle[1] }}</div>
  </div>
</template>

<script>
import { useI18n } from "vue-i18n";

export default {
  setup() {
    const { t } = useI18n();
    return { t };
  },
  props: {
    itemDetalle: Array,
  },
  computed: {
    detalle() {
      let aux = ["", ""];
      if (this.itemDetalle && this.itemDetalle.length > 0) {
        aux = [...this.itemDetalle];
        aux[0] = this.t(`app.reciboTransferencia.${this.itemDetalle[0]}`);
        if(this.itemDetalle[2]){
          aux[1] = this.t(`variables.${this.itemDetalle[0]}.${this.itemDetalle[1]}`);
          // if(this.itemDetalle[0] == ""){

          // }
        }else{
          aux[1] = this.itemDetalle[1];
        }
      }
      // console.log(aux, this.itemDetalle);
      return aux;
    },
  },
};
</script>

<style>
.cardReciboTransfCont {
  /* display: flex;
  justify-content: space-between; */
}
.cardReciboTransfContItem {
  margin-top: 0.9375rem;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 11px;
  line-height: 13px;
  letter-spacing: 1px;
  text-transform: uppercase;
  color: #801b1c;
}
.cardReciboTransfContValor {
  margin-top: 0.3125;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 11px;
  line-height: 13px;
  letter-spacing: 0.5px;
  color: #787878;
}
/* .cardReciboTransfContDataStyleSp {
} */
</style>